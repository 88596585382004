<script lang="ts">
    import { onMount } from 'svelte';
    import { createEventDispatcher } from 'svelte';
    import toast from 'svelte-french-toast';
    import type { Comment } from '../../interfaces/types';
    import MessengerContactBox from '@/elements/Feed/MessengerContactBox.svelte';
    import PostNewComment from '@/elements/Feed/PostNewComment.svelte';
    import PostComment from '@/elements/Feed/PostComment.svelte';

    const dispatch = createEventDispatcher();

    export let post: any;
    export let currentUser: any;
    export let data: any;
    export let routeName: string;
    export let PostsPaginator: any;
    export let commentsVisible = false;

    let isSubbed = post.isSubbed;
    let commentsWrapper: HTMLElement;
    let comments: Comment[] = [];
    let loadingComments = false;
    let commentsLoaded = false;
    let totalCommentsCount = 0;
    let nextPage = 2;
    let hasMoreComments = false;

    const csrfTokenMeta = document.querySelector('meta[name="csrf-token"]');
    const csrfToken = csrfTokenMeta ? csrfTokenMeta.getAttribute('content') || '' : '';

    $: if (commentsVisible && comments.length === 0 && !commentsLoaded) {
        loadComments();
    }

    onMount(() => {
        if (routeName === 'posts.get') {
            loadComments();
            commentsVisible = true;
        }
    });

    function handleCommentDeleted(event: CustomEvent) {
        const { commentId } = event.detail;
        comments = comments.filter((c) => c.id !== commentId);
        totalCommentsCount -= 1;
        post.comments_count -= 1;
        dispatch('commentsLoaded', { total: totalCommentsCount });
    }

    async function loadComments() {
        loadingComments = true;
        try {
            const response = await fetch(`/posts/comments?post_id=${post.id}&limit=9`);
            const result = await response.json();
            comments = result.data.data || [];
            totalCommentsCount = result.data.total || comments.length;
            hasMoreComments = !!result.data.next_page_url;
            commentsLoaded = true;

            dispatch('commentsLoaded', { total: totalCommentsCount });
        } catch (error) {
            console.error('Error loading comments:', error);
        } finally {
            loadingComments = false;
        }
    }

    async function handleShowMore() {
        if (!hasMoreComments) return;

        loadingComments = true;
        try {
            const response = await fetch(`/posts/comments?page=${nextPage}&post_id=${post.id}&limit=9`);
            const result = await response.json();

            comments = [...comments, ...result.data.data];
            hasMoreComments = !!result.data.next_page_url;

            nextPage += 1;

            dispatch('commentsLoaded', { total: totalCommentsCount });
        } catch (error) {
            console.error('Error loading more comments:', error);
        } finally {
            loadingComments = false;
        }
    }

    async function handleAddComment(event: CustomEvent<string>) {
        const message = event.detail;
        if (!message) return;

        try {
            const formData = new URLSearchParams();
            formData.append('message', message);
            formData.append('post_id', post.id.toString());

            const response = await fetch(`${app.baseUrl}/posts/comments/add`, {
                method: 'POST',
                headers: {
                    'X-CSRF-TOKEN': csrfToken,
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: formData.toString(),
                credentials: 'same-origin',
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const result = await response.json();

            if (result.success) {
                comments = [result.data, ...comments];
                totalCommentsCount += 1;
                post.comments_count += 1;

                dispatch('commentsLoaded', {
                    total: totalCommentsCount,
                    postId: post.id,
                });
                toast.success('Comment added');
            } else {
                throw new Error(result.errors?.[0] || 'Failed to add comment');
            }
        } catch (error) {
            toast.error(error instanceof Error ? error.message : 'Failed to add comment');
        }
    }

    async function handleReact(event: CustomEvent) {
        const { commentId } = event.detail;
        const comment = comments.find((c) => c.id === commentId);
        if (!comment) return;

        // Check if the user has already reacted
        const didReact = comment.userReacted;

        // Update UI optimistically
        comment.userReacted = !didReact;
        if (didReact) {
            comment.reactions = comment.reactions.filter((r) => r.user_id !== currentUser.id);
        } else {
            comment.reactions.push({ id: 'temp', user_id: currentUser.id });
        }

        try {
            const formData = new FormData();
            formData.append('type', 'comment');
            formData.append('action', didReact ? 'remove' : 'add');
            formData.append('id', commentId.toString());

            const response = await fetch(`${app.baseUrl}/posts/reaction`, {
                method: 'POST',
                headers: {
                    'X-CSRF-TOKEN': csrfToken,
                },
                body: formData,
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const result = await response.json();

            if (!result.success) {
                comment.userReacted = didReact;
                if (didReact) {
                    comment.reactions.push({ id: 'temp', user_id: currentUser.id });
                } else {
                    comment.reactions = comment.reactions.filter((r) => r.user_id !== currentUser.id);
                }
                throw new Error(result.errors?.[0] || 'An error occurred');
            }

            comments = [...comments]; // Force reactivity
        } catch (error) {
            toast.error(error instanceof Error ? error.message : 'Error reacting to comment');
        }
    }

    function handleReply(event: CustomEvent) {
        const { username } = event.detail;
        const textarea = document.querySelector('.new-post-comment-area textarea') as HTMLTextAreaElement;
        if (textarea) {
            textarea.value += ` @${username} `;
            textarea.focus();
        }
    }
</script>

{#if isSubbed}
    <div class="post-comments" class:d-none="{!commentsVisible}" id="{routeName === 'posts.get' ? 'comments' : ''}">
        <hr />

        <div class="post-comments-wrapper px-3" bind:this="{commentsWrapper}">
            {#if loadingComments && comments.length === 0}
                <div class="comments-loading-box">
                    <MessengerContactBox limit="{1}" />
                </div>
            {:else if comments.length === 0}
                <div class="no-comments-label pl-3">No comments yet.</div>
            {:else}
                {#each comments as comment (comment.id)}
                    <PostComment
                        {comment}
                        {currentUser}
                        on:react="{handleReact}"
                        on:reply="{handleReply}"
                        on:commentDeleted="{handleCommentDeleted}"
                    />
                {/each}
            {/if}
        </div>

        {#if hasMoreComments}
            <div class="show-all-comments-label pl-3">
                <a href="javascript:void(0)" on:click="{handleShowMore}">Show more</a>
            </div>
        {/if}

        {#if currentUser}
            <hr />
            <PostNewComment on:addComment="{handleAddComment}" {currentUser} />
        {/if}
    </div>
{/if}
